<template>
  <div>
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="basic/elevator-fault-phenomenon-dict-page"
      @selection-change="handleSelectionChange"
    >
      <el-button v-if="$auth('电梯故障现象管理新增')" slot="toolbar" type="primary" size="mini" @click="$refs.editPage.open()">
        {{$l("common.add", "新增")}}
      </el-button>
      <!--      <el-button slot="toolbar" type="primary" size="mini" @click="$refs.mergePage.open(selectList)">-->
      <!--        {{$l("common.add", "合并")}}-->
      <!--      </el-button>-->
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search :label="$l('user.name','故障现象')">
            <el-input v-model.trim="filter.name"></el-input>
          </vm-search>
        </div>
      </template>
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <!--      <el-table-column-->
      <!--        type="selection"-->
      <!--        width="55">-->
      <!--      </el-table-column>-->


      <el-table-column prop="name" label="故障现象" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate" width="130px">
        <template slot-scope="scope">
          <el-button v-if="$auth('电梯故障现象管理编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l("common.edit", "编辑")}}</el-button>
          <el-button v-if="$auth('电梯故障现象管理删除')" type="danger" @click="deleteRow(scope.row)">{{$l("common.delete", "删除")}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <merge-page ref="mergePage" @save-success="getList(-1)"></merge-page>
  </div>
</template>
<script>
  import EditPage from "./AppearanceEdit";
  import MergePage from "./BrandMerge";

  export default {

    components: {EditPage,MergePage},
    data() {
      return {
        filter: {
          phone: "",
          name: "",
          type: "",
          status: "",

        },
        selectList:[],
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
      },

      handleSelectionChange(val) {
        this.selectList = val;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("basic/elevator-manufacturer/", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },

    },
  };
</script>
<style lang="scss" scoped>
</style>
